/*
 * @Author: 哈库呐玛塔塔
 * @Date: 2022-11-14 16:55:42
 * @Descripttion: 
 * @LastEditors: 哈库呐玛塔塔
 * @LastEditTime: 2022-11-16 17:24:09
 */

import React, { useContext, useEffect, useState } from "react"
import MdWrapper from "../../../../../components/MarkdownWrap"
import { useLocation } from "@reach/router"
import Axios from "axios"

const Privacy = () => {
  const [md, setMd] = useState(null)
  const location = useLocation()
 
  useEffect(() => {
    document.documentElement.lang = "en"
    getMd()
  }, [])
  const getMd = () => {
    Axios.get(`${process.env.resourceUrl}docs/nebula_h5_docs/pp/kddi/PrivacyPolicy-EN.md`).then(function (data) {
      setMd(data.data)
    })

  }
  return (
    <MdWrapper md={md} blankLayout />
  )
}

export default Privacy
